import { useLocation, useNavigate } from 'react-router-dom';
import Home from '../../../assets/home.svg';
import { LoadingOutlined } from '@ant-design/icons';

const russianTranslations = {
    vacancies: 'Вакансии',
    candidates: "База кандидатов",
    calendar: "Календарь",
    settings: "Настройки"
};
const Breadcrumbs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const paths = location.pathname.split('/').filter((path) => path !== '');
    return (
        <>
            <li onClick={() => navigate('/')}>
                {/*<img src={Home} alt="HomeIcon"/>*/}
                <Home />
            </li>
            {paths.map((path, index) => {
                const routeTo = paths.slice(0, index + 1).join('/');
                const isLast = index === paths.length - 1;
                const translatedPath = russianTranslations[path] || path;

                return isLast ? (
                    <li key={index}>{translatedPath || <LoadingOutlined rev={undefined} />}</li>
                ) : (
                    <li
                        onClick={() => {
                            navigate(`/${routeTo}`);
                        }}
                        key={index}
                    >
                        {translatedPath || <LoadingOutlined rev={undefined} />}
                    </li>
                );
            })}
        </>
    );
};

export default Breadcrumbs;
