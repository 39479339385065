import {CandidateStatusApiFactory, CandidatesApiFactory, UserApiFactory, VacancyApiFactory} from '../generated/backend';

export const candidateStatusApi = CandidateStatusApiFactory({
    isJsonMime: (mime: string): boolean => mime.toLowerCase().includes('json'),
    basePath: window.location.origin,
});

export const candidatesApi = CandidatesApiFactory({
    isJsonMime: (mime: string): boolean => mime.toLowerCase().includes('json'),
    basePath: window.location.origin,
});

export const userApi = UserApiFactory({
    isJsonMime: (mime: string): boolean => mime.toLowerCase().includes('json'),
    basePath: window.location.origin,
});

export const vacancyApi = VacancyApiFactory({
    isJsonMime: (mime: string): boolean => mime.toLowerCase().includes('json'),
    basePath: window.location.origin,
});
