import axios from 'axios';
import { Navigate } from 'react-router-dom'

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                // window.location.href = '/login';
                return <Navigate to="/login" />;
            }
        }
        return Promise.reject(error);
    }
);
