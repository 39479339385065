import React, { FC } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { CandidateDtoResponse } from "../../generated/backend";
import styles from "../vacancies/vacancies.module.css";
import { Link } from "react-router-dom";

interface CandidateTableProps {
    candidates: CandidateDtoResponse[];
}

const CandidateTable: FC<CandidateTableProps> = ({ candidates }) => {
    console.log('Кандидаты для отображения:', candidates)

    if (!candidates || candidates.length === 0) {
        console.warn('Массив candidates пустой или не определен');
    }

    const columns: ColumnsType<CandidateDtoResponse> = [
        {
            key: "name",
            title: "Кандидаты",
            dataIndex: "name",
            render: (text, record) => (
                <Link to={`/candidates/${record.id}`}>
                    <div className={styles.vacanciesNameText}>{text}</div>
                </Link>
            ),
        },
        {
            key: "dateOfBirth",
            title: "Дата",
            dataIndex: "dateOfBirth",
        },
        {
            key: "notes",
            title: "Заметки",
            dataIndex: "notes",
        },
        {
            key: "email",
            title: "Email",
            dataIndex: "email",
        },
        {
            key: "phoneNumber",
            title: "Номер телефона",
            dataIndex: "phoneNumber",
        },
        {
            key: "telegram",
            title: "Telegram",
            dataIndex: "telegram",
        },
        {
            key: "citizenship",
            title: "Гражданство",
            dataIndex: "citizenship",
        },
    ];

    return (
        <Table<CandidateDtoResponse>
            columns={columns}
            dataSource={candidates}
            pagination={{ pageSize: 7 }}
            rowKey="id"
            rowSelection={{}}
        />
    );
};

export default CandidateTable;
