import {createAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserApiFactory, AuthRequest} from "../../generated/backend";
import {AxiosError} from "axios";

const userApi = UserApiFactory()

export interface GeneralState {
    token: string | null
    username: string
    password: string
    loginError: string | null
}

const initialState: GeneralState = {
    token: localStorage.getItem('token'),
    username: '',
    password: '',
    loginError: null
}

export const login = createAsyncThunk(
    'general/login',
    async (credentials: AuthRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await userApi.apiUserLoginPost(credentials);
            localStorage.setItem('token', response.data.accessToken);
            dispatch(setToken(response.data.accessToken));
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response && axiosError.response.data) {
                return rejectWithValue(axiosError.response.data);
            }
            return rejectWithValue(error.message);
        }
    }
);
export const setToken = createAction<string>('general/setToken');

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        changeUsername: (state, action: PayloadAction<string>) =>{
            state.username = action.payload
        },
        changePassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        clearLoginError: (state) => {
            state.loginError = null;
        },
    },
    extraReducers: (builder) =>{
        builder.addCase(login.fulfilled, (state, action) => {
            state.token = action.payload.accessToken
            localStorage.setItem('token', action.payload.accessToken)
        })
        builder.addCase(login.rejected, (state) => {
            state.loginError = "Логин или пароль написаны не верно";
            state.username = '';
            state.password = '';
        })
    }
})

export const {changeUsername, changePassword, clearLoginError} = generalSlice.actions

export default generalSlice.reducer
