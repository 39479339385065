import {FC, useEffect, useState} from "react";
import {Button, Space} from "antd";
import styles from '../components/vacancies/vacancies.module.css'
import {useLocation} from "react-router-dom";
import {CandidateDtoResponse, CandidateDtoRequest} from "../generated/backend";
import {useForm} from "antd/es/form/Form";
import {candidatesApi} from "../api/apis";
import CandidateCreateForm from "../components/candidates/CandidateCreateForm";
import CandidatesTable from "../components/candidates/CandidatesTable";

const VacancyPage: FC = () =>{
    const location = useLocation();
    const vacancyId = location.state?.vacancyId;
    const vacancyName = location.state?.vacancyName;
    const [openCandidateAddModal, setOpenCandidateAddModal] = useState(false);
    const [vacancyCandidates, setVacancyCandidates] = useState<CandidateDtoResponse[]>([])
    const [form] = useForm<CandidateDtoRequest>()

    const showCandidateAddModal = () => {
        setOpenCandidateAddModal(true);
    };

    const handleShowCandidateAddCancel = () => {
        setOpenCandidateAddModal(false);
        form.resetFields();
    };

    const onUpdateCandidates = async () => {
        if (!vacancyId) {
            console.error('vacancyId не указан')
            return
        }
        try {
            const response = await candidatesApi.apiVacancyIdCandidateGet(vacancyId)
            console.log('Кандидаты получены:', response.data)
            setVacancyCandidates(response.data)
        } catch (error) {
            if (error.response) {
                console.error('Ошибка при загрузке кандидатов вакансии:', error)
            } else if (error.request) {
                console.error('Нет ответа от сервера:', error.request)
            } else {
                console.error('Ошибка при настройке запроса:', error.message)
            }
        }
    };

    useEffect(() => {
        if (!vacancyId) {
            console.error('vacancyId не найден в location.state');
        }
        if (!vacancyName) {
            console.error('vacancyName не найден в location.state');
        }
        console.log('vacancyId:', vacancyId);
        onUpdateCandidates();
    }, [vacancyId]);

    useEffect(() => {
        console.log('Обновленные кандидаты:', vacancyCandidates);
    }, [vacancyCandidates]);


    return(
        <div>
            <Space className={styles.vacanciesTitle}>
                <h2>{vacancyName}</h2>
                <div>
                    <Button type="primary" onClick={showCandidateAddModal}>
                        Добавить кандидата
                    </Button>
                    <CandidateCreateForm
                        open={openCandidateAddModal}
                        onCancel={handleShowCandidateAddCancel}
                        vacancyId={vacancyId}
                        onUpdateCandidates={onUpdateCandidates}
                        form={form}
                    />
                </div>
            </Space>
            <CandidatesTable candidates={vacancyCandidates} />
        </div>
    )
}
export default VacancyPage