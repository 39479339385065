import React, { useState } from 'react';
import { message, Modal, Form, Input, Select, InputNumber} from 'antd';
import styles from './vacancies.module.css';
import {VacancyDtoResponse, VacancyDtoRequest } from '../../generated/backend';
import {vacancyApi} from "../../api/apis";

interface VacanciesCreateModalProps {
    updateVacancies: React.Dispatch<React.SetStateAction<VacancyDtoResponse[]>>,
    open: boolean,
    onCancel: () => void,
}
const VacanciesCreateModal: React.FC<VacanciesCreateModalProps> = ({updateVacancies, open, onCancel }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    const handleOk = async () => {
        try {
            const formData = await form.validateFields();
            setConfirmLoading(true);
            console.log(formData);
            const response = await vacancyApi.apiVacancyPost({
                ...formData,
                // authorId: 1,
            });
            message.success(`Вакансия успешно создана "${response.data.name}"`);
            const updatedVacanciesResponse = await vacancyApi.apiVacancyGet();
            const updatedVacancies = updatedVacanciesResponse.data;
            updateVacancies(updatedVacancies);
            onCancel()
            setConfirmLoading(false);
            form.resetFields();
        } catch (error) {
            console.error('Ошибка при создании вакансии:', error);
            message.error('Ошибка при создании вакансии');
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        onCancel();
        form.resetFields();
    };

    return (
        <>
            <Modal
                className={styles.modal}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                width={650}
                okText="Создать"
                cancelText="Отмена"
                styles={{ body: { overflow: 'auto', maxHeight: 'calc(100vh - 300px)' } }}
            >
                <h2 className={styles.vacancyFormTitle}>Новая вакансия</h2>
                <Form
                    form={form}
                    name='createVacancy'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    style={{ maxWidth: 650 }}
                    autoComplete="off"
                    initialValues={{ status: 'OPEN' }}
                >
                    <Form.Item
                        label="Название вакансии"
                        name="name"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Статус"
                        name="status"
                        rules={[{required: true, message: 'Обязательное поле'}]}
                    >
                        <Select>
                            <Select.Option value="OPEN">В работе</Select.Option>
                            <Select.Option value="PAUSED">В паузе</Select.Option>
                            <Select.Option value="ARCHIVED">В архиве</Select.Option>
                            <Select.Option value="CLOSED">Закрыта</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Отдел"
                        name="department"
                        rules={[{required: true, message: 'Обязательное поле'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Город"
                        name="city"
                        rules={[{required: true, message: 'Обязательное поле'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Зарплата от"
                        name="salaryMin"
                        rules={[{ type: 'number'}, { required: true, message: 'Обязательное поле' }]}
                    >
                        <InputNumber type='number' placeholder="От" min={0}/>
                    </Form.Item>
                    <Form.Item<VacancyDtoRequest>
                        label="Зарплата до"
                        name="salaryMax"
                        rules={[{ type: 'number'}, { required: true, message: 'Обязательное поле' }]}
                    >
                        <InputNumber type='number' placeholder="До" min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Примечания"
                        name="notes"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                    <Form.Item
                        label="Описание"
                        name="description"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                    <Form.Item
                        label="Тестовое задание"
                        name="testTask"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Источник"
                        name="placed"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default VacanciesCreateModal;
