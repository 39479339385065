import React from 'react';
import { FC } from 'react';
import {Form, Input, Modal, message, FormInstance, DatePicker} from 'antd';
import { CandidateDtoRequest } from '../../generated/backend';
import { candidatesApi } from '../../api/apis';
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";

interface CandidateCreateFormProps {
    open: boolean;
    onCancel: () => void;
    vacancyId: string | undefined;
    onUpdateCandidates: () => void;
    form: FormInstance<CandidateDtoRequest>;
}

const CandidateCreateForm: FC<CandidateCreateFormProps> = ({ open, onCancel, onUpdateCandidates, form, vacancyId }) => {

    const handleCreateCandidate = async () => {
        try {
            const formData = form.getFieldsValue();
            if (vacancyId) {
                formData.dateOfBirth = moment(formData.dateOfBirth).format('DD.MM.YYYY');
                const response = await candidatesApi.apiVacancyIdCandidatePost(parseInt(vacancyId), formData);
                console.log('Кандидат успешно создан:', response.data);
                message.success(`Кандидат ${response.data.name} успешно создан`);
                await onUpdateCandidates();
                onCancel();
            } else {
                message.error('Ошибка: id вакансии не найден');
            }
        } catch (error) {
            console.error('Ошибка при создании кандидата:', error);
            message.error('Ошибка при создании кандидата');
        }
    };

    return (
        <Modal
            title="Добавить кандидата"
            open={open}
            onCancel={onCancel}
            onOk={handleCreateCandidate}
            okText="Добавить"
            cancelText="Отмена"
        >
            <Form
                form={form}
                name="createCandidate"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                autoComplete="off"
            >
                <Form.Item<CandidateDtoRequest>
                    label="Имя кандидата"
                    name="name"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<CandidateDtoRequest>
                    label="Дата рождения"
                    name="dateOfBirth"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <DatePicker style={{ width: '100%' }} locale={locale } format="DD.MM.YYYY" />
                </Form.Item>
                <Form.Item<CandidateDtoRequest>
                    label="Заметки"
                    name="notes"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<CandidateDtoRequest>
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<CandidateDtoRequest>
                    label="Номер телефона"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item<CandidateDtoRequest>
                    label="Telegram"
                    name="telegram"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<CandidateDtoRequest>
                    label="Гражданство"
                    name="citizenship"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CandidateCreateForm;
