import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo-black.svg';
import { RootState, useAppDispatch } from '../../store/store';
import { changePassword, changeUsername, login, clearLoginError } from '../../store/slices/generalSlice';
import styles from './login.module.css';
import { Button, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

export function Login() {
    const generalState = useSelector((state: RootState) => state.general);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (generalState.token) {
            navigate('/');
        }
    }, [generalState.token, navigate]);

    useEffect(() => {
        return () => {
            dispatch(clearLoginError());
        };
    }, [dispatch]);

    const handleSubmit = () => {
        dispatch(
            login({
                username: generalState.username,
                password: generalState.password
            })
        );
    };

    return (
        <div className={styles.container}>
            <Form
                className={styles.loginForm}
                onFinish={handleSubmit}
            >
                <div className={styles.logotip}>
                    <Logo />
                </div>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Введите имя пользователя'
                        }
                    ]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        placeholder="Имя пользователя"
                        value={generalState.username}
                        onChange={event =>
                            dispatch(changeUsername(event.target.value))
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Пароль"
                        value={generalState.password}
                        onChange={event =>
                            dispatch(changePassword(event.target.value))
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <Checkbox>Запомнить</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        type="primary"
                        className={styles.button}
                        // onClick={() => {
                        //     dispatch(
                        //         login({
                        //             username: generalState.username,
                        //             password: generalState.password
                        //         })
                        //     );
                        // }}
                        disabled={!generalState.password || !generalState.username}
                    >
                        Войти
                    </Button>
                </Form.Item>
                {generalState.loginError && (
                    <div className={styles.error}>{generalState.loginError}</div>
                )}
            </Form>
        </div>
    );
}
