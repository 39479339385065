import React, { useState, useEffect } from 'react';
import {Table, Space, Button, message} from 'antd';
import styles from '../components/vacancies/vacancies.module.css';
import {VacancyDtoResponse} from '../generated/backend';
import VacanciesDeleteModal from '../components/vacancies/VacanciesDeleteModal';
import {useForm} from "antd/es/form/Form";
import {FormOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import VacanciesCreateModal from "../components/vacancies/VacanciesCreateModal";
import {vacancyApi} from "../api/apis";
import VacanciesChangeForm from "../components/vacancies/VacanciesChangeForm";

const VacanciesPage: React.FC = () => {
    const [vacancies, setVacancies] = useState<VacancyDtoResponse[]>([]);
    const [openChangeModal, setOpenChangeModal] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState<VacancyDtoResponse | null>(null);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [form] = useForm()

    const showChangeModal = (record: VacancyDtoResponse) =>{
        setSelectedRecord(record)
        form.setFieldsValue(record);
        setOpenChangeModal(true)
    }

    const handleDeleteConfirm = async (record: VacancyDtoResponse) => {
        try {
            const response = await vacancyApi.apiVacancyIdDelete(record?.id);
            if (response.status === 200) {
                console.log(`Вакансия "${record?.name}" успешно удалена`);
                message.success(`Вакансия "${record?.name}" успешно удалена`);
                const updatedVacanciesResponse = await vacancyApi.apiVacancyGet();
                const updatedVacancies = updatedVacanciesResponse.data;
                setVacancies(updatedVacancies);
            } else {
                console.log('Ошибка при удалении вакансии', response);
                message.error(`Ошибка при удалении вакансии "${record?.name}"`);
            }
        } catch (error) {
            console.error('Ошибка при удалении вакансии', error);
            message.error('Ошибка при удалении вакансии');
        }
    };

    useEffect(() => {
        const fetchVacancies = async () => {
            try {
                const response = await vacancyApi.apiVacancyGet();
                if (response.data && Array.isArray(response.data)) {
                    setVacancies(response.data);
                } else {
                    console.error('Некорректный формат данных, ожидался массив', response.data);
                }
            } catch (error) {
                console.error('Ошибка при загрузке вакансий', error);
            }
        };
        fetchVacancies();
    }, []);

    const onUpdateVacancies = async () => {
        const updatedVacanciesResponse = await vacancyApi.apiVacancyGet();
        const updatedVacancies = updatedVacanciesResponse.data;
        setVacancies(updatedVacancies);
    };

    const columns = [
        {
            key: 'name',
            title: 'Вакансия',
            dataIndex: 'name',
            render: (text: string, record: VacancyDtoResponse) => (
                <div className={styles.vacanciesName}>
                    <Link to={`/vacancies/${record.id}`} state={{ vacancyId: record.id, vacancyName: record.name }}>
                        <div className={styles.vacanciesNameText}>{text}</div>
                    </Link>
                    <Space>
                        <Button
                            onClick={() => showChangeModal(record)}
                            type='link'
                            icon={<FormOutlined />}
                        />
                        <VacanciesDeleteModal record={record} onDeleteConfirm={handleDeleteConfirm} />
                    </Space>
                </div>
            )
        },
        {
            key: 'id',
            title: 'id',
            dataIndex: 'id',
        },
        {
            key: 'department',
            title: 'Отдел',
            dataIndex: 'department',
        },
        {
            key: 'city',
            title: 'Город',
            dataIndex: 'city',
        },
        {
            key: 'testTask',
            title: 'Тестовое задание',
            dataIndex: 'testTask',
        },
        {
            key: 'salary',
            title: 'Зарплата',
            render: (text: string, record: VacancyDtoResponse) => (
                <span>{record.salaryMin} - {record.salaryMax}</span>
            )
        },
    ];

    return (
        <div>
            <Space className={styles.vacanciesTitle}>
                <h2>Вакансии</h2>
                <Button type="primary" onClick={() => setOpenCreateModal(true)}>
                    Создать вакансию
                </Button>
            </Space>
            <Table
                columns={columns}
                dataSource={vacancies}
                pagination={{ pageSize: 7 }}
                rowKey='id'
            />
            <VacanciesCreateModal
                updateVacancies={setVacancies}
                open={openCreateModal}
                onCancel={() => setOpenCreateModal(false)}
            />
            <VacanciesChangeForm
                open={openChangeModal}
                onCancel={() => setOpenChangeModal(false)}
                vacancy={selectedRecord}
                onUpdate={onUpdateVacancies}
            />
        </div>
    );
};

export default VacanciesPage;