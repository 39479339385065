import React, {useEffect} from 'react';
import { Modal, Form, Input, Select, InputNumber, message } from 'antd';
import { VacancyDtoResponse, VacancyDtoRequest } from '../../generated/backend';
import { useForm } from 'antd/es/form/Form';
import { vacancyApi } from '../../api/apis';

interface VacanciesChangeFormProps {
    open: boolean;
    onCancel: () => void;
    vacancy: VacancyDtoResponse;
    onUpdate: () => void;
}

const VacanciesChangeForm: React.FC<VacanciesChangeFormProps> = ({ open, onCancel, vacancy, onUpdate }) => {
    const [form] = useForm();

    const handleChangeOk = async () => {
        try {
            const formData = form.getFieldsValue();
            const updatedVacancyData = {
                ...formData,
                // authorId: 1,
            };
            const response = await vacancyApi.apiVacancyIdPut(vacancy.id, updatedVacancyData);
            if (response.status === 200) {
                console.log(`Вакансия "${vacancy.name}" успешно изменена`, response.data);
                message.success(`Вакансия "${vacancy.name}" успешно изменена`);
                onUpdate();
            } else {
                console.log('Ошибка при изменении вакансии', response);
                message.error(`Ошибка при изменении вакансии "${vacancy.name}"`);
            }
        } catch (error) {
            console.error('Ошибка при изменении вакансии', error);
            message.error('Ошибка при изменении вакансии');
        } finally {
            onCancel();
        }
    };

    useEffect(() => {
        if (open && vacancy) {
            form.setFieldsValue(vacancy);
        }
    }, [open, vacancy, form]);

    return (
        <Modal
            title="Изменение вакансии"
            open={open}
            onOk={handleChangeOk}
            onCancel={onCancel}
            okText="Сохранить"
            cancelText="Отмена"
        >
            <Form
                form={form}
                name="changeVacancy"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                style={{ maxWidth: 650 }}
                autoComplete="off"
                initialValues={{ ...vacancy }}
                onFinish={handleChangeOk}
            >
                <Form.Item<VacancyDtoRequest>
                    label="Название вакансии"
                    name="name"
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Статус"
                    name="status"
                >
                    <Select>
                        <Select.Option value="OPEN">В работе</Select.Option>
                        <Select.Option value="PAUSED">В паузе</Select.Option>
                        <Select.Option value="ARCHIVED">В архиве</Select.Option>
                        <Select.Option value="CLOSED">Закрыта</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Отдел"
                    name="department"
                >
                    <Input />
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Город"
                    name="city"
                >
                    <Input />
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Зарплата от"
                    name="salaryMin"
                    rules={[{ type: 'number' }, { required: true, message: 'Обязательное поле' }]}
                >
                    <InputNumber type='number' placeholder="От" />
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Зарплата до"
                    name="salaryMax"
                    rules={[{ type: 'number' }, { required: true, message: 'Обязательное поле' }]}
                >
                    <InputNumber type='number' placeholder="До" />
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Примечания"
                    name="notes"
                >
                    <Input.TextArea rows={3} />
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Описание"
                    name="description"
                >
                    <Input.TextArea rows={3} />
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Тестовое задание"
                    name="testTask"
                >
                    <Input />
                </Form.Item>
                <Form.Item<VacancyDtoRequest>
                    label="Источник"
                    name="placed"
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default VacanciesChangeForm;
