import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {CandidateDtoResponse} from "../../generated/backend";

interface CandidateDeleteModalProps {
    candidate: CandidateDtoResponse;
    onDeleteConfirm: (candidate: CandidateDtoResponse) => void;
}

const CandidateDeleteModal: React.FC<CandidateDeleteModalProps> = ({ candidate, onDeleteConfirm }) => {
    const [open, setOpen] = useState(false);

    const handleDelete = () => {
        onDeleteConfirm(candidate);
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => setOpen(true)}
            />
            <Modal
                title="Удаление кандидата"
                open={open}
                onOk={handleDelete}
                onCancel={handleCancel}
                okText="Удалить"
                okType="danger"
                cancelText="Отмена"
            >
                <p>{`Вы уверены, что хотите удалить кандидата "${candidate?.name}"?`}</p>
            </Modal>
        </>
    );
};

export default CandidateDeleteModal;
