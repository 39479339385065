import { FC, useEffect, useState } from "react";
import { message, Space, Spin} from "antd";
import { CandidateDtoResponse, CandidatesApiFactory } from "../generated/backend";
import styles from '../components/vacancies/vacancies.module.css'
import {useParams, useNavigate} from "react-router-dom";
import CandidateDeleteModal from "../components/candidates/CandidateDeleteModal";
import CandidateChangeForm from "../components/candidates/CandidateChangeForm";

const CandidatePage: FC = () => {
    const { candidateId } = useParams<{ candidateId: string }>()
    const [candidate, setCandidate] = useState<CandidateDtoResponse | null>(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()


    const fetchCandidate = async () => {
        try {
            if (candidateId && !isNaN(parseInt(candidateId))) {
                const response = await CandidatesApiFactory().apiCandidateIdGet(parseInt(candidateId))
                setCandidate(response.data)
            } else {
                console.error("Некорректный candidateId:", candidateId)
            }
        } catch (error) {
            console.error("Ошибка при загрузке данных кандидата", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchCandidate()
    }, [candidateId])

    const handleDeleteCandidate = async (candidate: CandidateDtoResponse) => {
        try {
            await CandidatesApiFactory().apiCandidateIdDelete(candidate.id)
            message.success(`Кандидат ${candidate.name} успешно удален!`)
            navigate('/vacancies')
        } catch (error) {
            console.error("Ошибка при удалении кандидата", error)
            message.error(`Ошибка при удалении кандидата ${candidate.name}`)
        }
    }

    return (
        <div>
            {loading ? (
                <Spin size="large" />
            ) : (
                <div>
                    <div className={styles.candidateTitle}>
                        <h2>{candidate?.name}</h2>
                        <Space>
                            <CandidateChangeForm candidate={candidate} onUpdate={fetchCandidate}/>
                            <CandidateDeleteModal candidate={candidate} onDeleteConfirm={handleDeleteCandidate} />
                        </Space>
                    </div>
                    <ul className={styles.descriptionsCandidate}>
                        <li><span>Заметки:</span> {candidate?.notes}</li>
                        <li><span>Email:</span> {candidate?.email}</li>
                        <li><span>Номер телефона:</span> {candidate?.phoneNumber}</li>
                        <li><span>Telegram:</span> {candidate?.telegram}</li>
                        <li><span>Гражданство:</span> {candidate?.citizenship}</li>
                    </ul>
                </div>
            )}
        </div>
    )
};

export default CandidatePage
