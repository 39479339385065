import './App.css'
import Layout from "./components/layout";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import VacanciesPage from "./pages/VacanciesPage";
import CandidatesPage from "./pages/CandidatesPage";
import CalendarPage from "./pages/CalendarPage";
import SettingsPage from "./pages/SettingsPage";
import WelcomePage from "./pages/WelcomePage";
import VacancyPage from "./pages/VacancyPage";
import CandidatePage from "./pages/CandidatePage";
import {Login} from "./components/login/Login";
import {useSelector} from "react-redux";
import {RootState} from "./store/store";
import './api.include'

function App() {
  const generalState = useSelector((state: RootState) => state.general)
  return (
      <div>
          <Router>
              <Routes>
                  <Route path='/login' element={<Login/>}/>
                  <Route path="/" element={generalState.token ? <Layout /> : <Navigate to='/login'/>}>
                      <Route index element={<WelcomePage />} />
                      <Route path="/vacancies" element={<VacanciesPage />} />
                      <Route path="/candidates" element={<CandidatesPage />} />
                      <Route path="/calendar" element={<CalendarPage/>} />
                      <Route path="/settings" element={<SettingsPage/>} />
                      <Route path="/vacancies/:id" element={<VacancyPage/>} />
                      <Route path="/candidates/:candidateId" element={<CandidatePage/>} />
                  </Route>
              </Routes>
          </Router>
      </div>
  )
}

export default App
