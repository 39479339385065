import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { VacancyDtoResponse } from '../../generated/backend';

interface VacanciesDeleteModalProps {
    record: VacancyDtoResponse;
    onDeleteConfirm: (record: VacancyDtoResponse) => void;
}

const VacanciesDeleteModal: React.FC<VacanciesDeleteModalProps> = ({ record, onDeleteConfirm }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleDeleteCancel = () => setOpenDeleteModal(false);

    return (
        <>
            <Button
                onClick={() => setOpenDeleteModal(true)}
                type="link"
                icon={<DeleteOutlined />}
            />
            <Modal
                title="Удаление вакансии"
                open={openDeleteModal}
                onOk={() => onDeleteConfirm(record)}
                onCancel={handleDeleteCancel}
                okText="Удалить"
                okType="danger"
                cancelText="Отмена"
            >
                <p>{`Вы уверены, что хотите удалить вакансию "${record?.name}"?`}</p>
            </Modal>
        </>
    );
};

export default VacanciesDeleteModal;
