import React, { FC, useState } from "react";
import { Modal, Button, Form, Input, message } from "antd";
import { FormOutlined } from "@ant-design/icons";
import {CandidateDtoRequest, CandidateDtoResponse} from "../../generated/backend";
import {candidatesApi} from "../../api/apis";

interface CandidateChangeFormProps {
    candidate: CandidateDtoResponse | null
    onUpdate: () => void
}

const CandidateChangeForm: FC<CandidateChangeFormProps> = ({ candidate, onUpdate }) => {
    const [open, setOpen] = useState(false)
    const [form] = Form.useForm()

    const showModal = () => {
        form.setFieldsValue(candidate)
        setOpen(true)
    }

    const handleCancel = () => {
        setOpen(false)
    }

    const handleSave = async () => {
        try {
            const values = await form.validateFields()
            if (candidate) {
                await candidatesApi.apiCandidateIdPut(candidate.id, values)
                message.success(`Кандидат ${candidate.name} успешно обновлен!`)
                onUpdate()
                setOpen(false)
            }
        } catch (error) {
            console.error("Ошибка при обновлении кандидата", error)
            message.error(`Ошибка при обновлении кандидата ${candidate?.name}`)
        }
    }

    return (
        <>
            <Button type='link' icon={<FormOutlined />} onClick={showModal} />
            <Modal
                title="Изменение данных кандидата"
                open={open}
                onCancel={handleCancel}
                onOk={handleSave}
                okText="Сохранить"
                cancelText="Отмена"
            >
                <Form
                    form={form}
                    name="updateCandidate"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    autoComplete="off"
                >
                    <Form.Item<CandidateDtoRequest>
                        label="Имя кандидата"
                        name="name"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<CandidateDtoRequest>
                        label="Дата рождения"
                        name="dateOfBirth"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<CandidateDtoRequest>
                        label="Заметки"
                        name="notes"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<CandidateDtoRequest>
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<CandidateDtoRequest>
                        label="Номер телефона"
                        name="phoneNumber"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<CandidateDtoRequest>
                        label="Telegram"
                        name="telegram"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item<CandidateDtoRequest>
                        label="Гражданство"
                        name="citizenship"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CandidateChangeForm
