/* tslint:disable */
/* eslint-disable */
/**
 * hr API
 * hr API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface CandidateDtoRequest
 */
export interface CandidateDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoRequest
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoRequest
     */
    'citizenship'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoRequest
     */
    'telegram'?: string;
}
/**
 * 
 * @export
 * @interface CandidateDtoResponse
 */
export interface CandidateDtoResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateDtoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoResponse
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoResponse
     */
    'citizenship'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoResponse
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateDtoResponse
     */
    'telegram'?: string;
}
/**
 * 
 * @export
 * @interface CandidateStatusDtoRequest
 */
export interface CandidateStatusDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof CandidateStatusDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateStatusDtoRequest
     */
    'type'?: CandidateStatusDtoRequestTypeEnum;
}

export const CandidateStatusDtoRequestTypeEnum = {
    Created: 'CREATED',
    InProgress: 'IN_PROGRESS',
    Hired: 'HIRED',
    Rejected: 'REJECTED'
} as const;

export type CandidateStatusDtoRequestTypeEnum = typeof CandidateStatusDtoRequestTypeEnum[keyof typeof CandidateStatusDtoRequestTypeEnum];

/**
 * 
 * @export
 * @interface CandidateStatusDtoResponse
 */
export interface CandidateStatusDtoResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateStatusDtoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CandidateStatusDtoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateStatusDtoResponse
     */
    'type'?: CandidateStatusDtoResponseTypeEnum;
}

export const CandidateStatusDtoResponseTypeEnum = {
    Created: 'CREATED',
    InProgress: 'IN_PROGRESS',
    Hired: 'HIRED',
    Rejected: 'REJECTED'
} as const;

export type CandidateStatusDtoResponseTypeEnum = typeof CandidateStatusDtoResponseTypeEnum[keyof typeof CandidateStatusDtoResponseTypeEnum];

/**
 * 
 * @export
 * @interface RefreshRequest
 */
export interface RefreshRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshRequest
     */
    'requestToken'?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'role'?: UserDtoRoleEnum;
}

export const UserDtoRoleEnum = {
    Admin: 'ADMIN',
    Recruiter: 'RECRUITER',
    Customer: 'CUSTOMER'
} as const;

export type UserDtoRoleEnum = typeof UserDtoRoleEnum[keyof typeof UserDtoRoleEnum];

/**
 * 
 * @export
 * @interface VacancyCandidateDtoRequest
 */
export interface VacancyCandidateDtoRequest {
    /**
     * 
     * @type {number}
     * @memberof VacancyCandidateDtoRequest
     */
    'candidate_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VacancyCandidateDtoRequest
     */
    'vacancy_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VacancyCandidateDtoRequest
     */
    'status_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VacancyCandidateDtoRequest
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyCandidateDtoRequest
     */
    'resume'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyCandidateDtoRequest
     */
    'overview'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyCandidateDtoRequest
     */
    'expected_salary'?: string;
}
/**
 * 
 * @export
 * @interface VacancyCandidateDtoResponse
 */
export interface VacancyCandidateDtoResponse {
    /**
     * 
     * @type {number}
     * @memberof VacancyCandidateDtoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {CandidateDtoResponse}
     * @memberof VacancyCandidateDtoResponse
     */
    'candidate'?: CandidateDtoResponse;
    /**
     * 
     * @type {VacancyDtoResponse}
     * @memberof VacancyCandidateDtoResponse
     */
    'vacancy'?: VacancyDtoResponse;
    /**
     * 
     * @type {CandidateStatusDtoResponse}
     * @memberof VacancyCandidateDtoResponse
     */
    'status'?: CandidateStatusDtoResponse;
    /**
     * 
     * @type {string}
     * @memberof VacancyCandidateDtoResponse
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyCandidateDtoResponse
     */
    'resume'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyCandidateDtoResponse
     */
    'overview'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyCandidateDtoResponse
     */
    'expected_salary'?: string;
}
/**
 * 
 * @export
 * @interface VacancyDtoRequest
 */
export interface VacancyDtoRequest {
    /**
     * 
     * @type {number}
     * @memberof VacancyDtoRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoRequest
     */
    'status'?: VacancyDtoRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoRequest
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoRequest
     */
    'city'?: string;
    /**
     * 
     * @type {number}
     * @memberof VacancyDtoRequest
     */
    'salaryMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof VacancyDtoRequest
     */
    'salaryMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoRequest
     */
    'testTask'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoRequest
     */
    'placed'?: string;
}

export const VacancyDtoRequestStatusEnum = {
    Open: 'OPEN',
    Paused: 'PAUSED',
    Archived: 'ARCHIVED',
    Closed: 'CLOSED'
} as const;

export type VacancyDtoRequestStatusEnum = typeof VacancyDtoRequestStatusEnum[keyof typeof VacancyDtoRequestStatusEnum];

/**
 * 
 * @export
 * @interface VacancyDtoResponse
 */
export interface VacancyDtoResponse {
    /**
     * 
     * @type {number}
     * @memberof VacancyDtoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoResponse
     */
    'status'?: VacancyDtoResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoResponse
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoResponse
     */
    'city'?: string;
    /**
     * 
     * @type {number}
     * @memberof VacancyDtoResponse
     */
    'salaryMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof VacancyDtoResponse
     */
    'salaryMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoResponse
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoResponse
     */
    'testTask'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyDtoResponse
     */
    'placed'?: string;
}

export const VacancyDtoResponseStatusEnum = {
    Open: 'OPEN',
    Paused: 'PAUSED',
    Archived: 'ARCHIVED',
    Closed: 'CLOSED'
} as const;

export type VacancyDtoResponseStatusEnum = typeof VacancyDtoResponseStatusEnum[keyof typeof VacancyDtoResponseStatusEnum];


/**
 * CandidateStatusApi - axios parameter creator
 * @export
 */
export const CandidateStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/candidate-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCandidateStatusIdDelete', 'id', id)
            const localVarPath = `/api/candidate-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCandidateStatusIdGet', 'id', id)
            const localVarPath = `/api/candidate-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateStatusDtoRequest} candidateStatusDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusIdPut: async (id: number, candidateStatusDtoRequest: CandidateStatusDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCandidateStatusIdPut', 'id', id)
            // verify required parameter 'candidateStatusDtoRequest' is not null or undefined
            assertParamExists('apiCandidateStatusIdPut', 'candidateStatusDtoRequest', candidateStatusDtoRequest)
            const localVarPath = `/api/candidate-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidateStatusDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CandidateStatusDtoRequest} candidateStatusDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusPost: async (candidateStatusDtoRequest: CandidateStatusDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateStatusDtoRequest' is not null or undefined
            assertParamExists('apiCandidateStatusPost', 'candidateStatusDtoRequest', candidateStatusDtoRequest)
            const localVarPath = `/api/candidate-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidateStatusDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidateStatusApi - functional programming interface
 * @export
 */
export const CandidateStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CandidateStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateStatusGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateStatusDtoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateStatusGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidateStatusApi.apiCandidateStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateStatusIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateStatusDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateStatusIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidateStatusApi.apiCandidateStatusIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateStatusIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateStatusDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateStatusIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidateStatusApi.apiCandidateStatusIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateStatusDtoRequest} candidateStatusDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateStatusIdPut(id: number, candidateStatusDtoRequest: CandidateStatusDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateStatusDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateStatusIdPut(id, candidateStatusDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidateStatusApi.apiCandidateStatusIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CandidateStatusDtoRequest} candidateStatusDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateStatusPost(candidateStatusDtoRequest: CandidateStatusDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateStatusDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateStatusPost(candidateStatusDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidateStatusApi.apiCandidateStatusPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CandidateStatusApi - factory interface
 * @export
 */
export const CandidateStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CandidateStatusApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusGet(options?: any): AxiosPromise<Array<CandidateStatusDtoResponse>> {
            return localVarFp.apiCandidateStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusIdDelete(id: number, options?: any): AxiosPromise<CandidateStatusDtoResponse> {
            return localVarFp.apiCandidateStatusIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusIdGet(id: number, options?: any): AxiosPromise<CandidateStatusDtoResponse> {
            return localVarFp.apiCandidateStatusIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateStatusDtoRequest} candidateStatusDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusIdPut(id: number, candidateStatusDtoRequest: CandidateStatusDtoRequest, options?: any): AxiosPromise<CandidateStatusDtoResponse> {
            return localVarFp.apiCandidateStatusIdPut(id, candidateStatusDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CandidateStatusDtoRequest} candidateStatusDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateStatusPost(candidateStatusDtoRequest: CandidateStatusDtoRequest, options?: any): AxiosPromise<CandidateStatusDtoResponse> {
            return localVarFp.apiCandidateStatusPost(candidateStatusDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandidateStatusApi - object-oriented interface
 * @export
 * @class CandidateStatusApi
 * @extends {BaseAPI}
 */
export class CandidateStatusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateStatusApi
     */
    public apiCandidateStatusGet(options?: RawAxiosRequestConfig) {
        return CandidateStatusApiFp(this.configuration).apiCandidateStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateStatusApi
     */
    public apiCandidateStatusIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return CandidateStatusApiFp(this.configuration).apiCandidateStatusIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateStatusApi
     */
    public apiCandidateStatusIdGet(id: number, options?: RawAxiosRequestConfig) {
        return CandidateStatusApiFp(this.configuration).apiCandidateStatusIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CandidateStatusDtoRequest} candidateStatusDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateStatusApi
     */
    public apiCandidateStatusIdPut(id: number, candidateStatusDtoRequest: CandidateStatusDtoRequest, options?: RawAxiosRequestConfig) {
        return CandidateStatusApiFp(this.configuration).apiCandidateStatusIdPut(id, candidateStatusDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CandidateStatusDtoRequest} candidateStatusDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateStatusApi
     */
    public apiCandidateStatusPost(candidateStatusDtoRequest: CandidateStatusDtoRequest, options?: RawAxiosRequestConfig) {
        return CandidateStatusApiFp(this.configuration).apiCandidateStatusPost(candidateStatusDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CandidatesApi - axios parameter creator
 * @export
 */
export const CandidatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/candidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCandidateIdDelete', 'id', id)
            const localVarPath = `/api/candidate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCandidateIdGet', 'id', id)
            const localVarPath = `/api/candidate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateIdPut: async (id: number, candidateDtoRequest: CandidateDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCandidateIdPut', 'id', id)
            // verify required parameter 'candidateDtoRequest' is not null or undefined
            assertParamExists('apiCandidateIdPut', 'candidateDtoRequest', candidateDtoRequest)
            const localVarPath = `/api/candidate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidateDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidatePost: async (candidateDtoRequest: CandidateDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateDtoRequest' is not null or undefined
            assertParamExists('apiCandidatePost', 'candidateDtoRequest', candidateDtoRequest)
            const localVarPath = `/api/candidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidateDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdCandidateGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacancyIdCandidateGet', 'id', id)
            const localVarPath = `/api/vacancy/{id}/candidate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdCandidatePost: async (id: number, candidateDtoRequest: CandidateDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacancyIdCandidatePost', 'id', id)
            // verify required parameter 'candidateDtoRequest' is not null or undefined
            assertParamExists('apiVacancyIdCandidatePost', 'candidateDtoRequest', candidateDtoRequest)
            const localVarPath = `/api/vacancy/{id}/candidate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidateDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidatesApi - functional programming interface
 * @export
 */
export const CandidatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CandidatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateDtoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidatesApi.apiCandidateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidatesApi.apiCandidateIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidatesApi.apiCandidateIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidateIdPut(id: number, candidateDtoRequest: CandidateDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidateIdPut(id, candidateDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidatesApi.apiCandidateIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCandidatePost(candidateDtoRequest: CandidateDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCandidatePost(candidateDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidatesApi.apiCandidatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyIdCandidateGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateDtoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyIdCandidateGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidatesApi.apiVacancyIdCandidateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyIdCandidatePost(id: number, candidateDtoRequest: CandidateDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyIdCandidatePost(id, candidateDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CandidatesApi.apiVacancyIdCandidatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CandidatesApi - factory interface
 * @export
 */
export const CandidatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CandidatesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateGet(options?: any): AxiosPromise<Array<CandidateDtoResponse>> {
            return localVarFp.apiCandidateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateIdDelete(id: number, options?: any): AxiosPromise<CandidateDtoResponse> {
            return localVarFp.apiCandidateIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateIdGet(id: number, options?: any): AxiosPromise<CandidateDtoResponse> {
            return localVarFp.apiCandidateIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidateIdPut(id: number, candidateDtoRequest: CandidateDtoRequest, options?: any): AxiosPromise<CandidateDtoResponse> {
            return localVarFp.apiCandidateIdPut(id, candidateDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCandidatePost(candidateDtoRequest: CandidateDtoRequest, options?: any): AxiosPromise<CandidateDtoResponse> {
            return localVarFp.apiCandidatePost(candidateDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdCandidateGet(id: number, options?: any): AxiosPromise<Array<CandidateDtoResponse>> {
            return localVarFp.apiVacancyIdCandidateGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CandidateDtoRequest} candidateDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdCandidatePost(id: number, candidateDtoRequest: CandidateDtoRequest, options?: any): AxiosPromise<CandidateDtoResponse> {
            return localVarFp.apiVacancyIdCandidatePost(id, candidateDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandidatesApi - object-oriented interface
 * @export
 * @class CandidatesApi
 * @extends {BaseAPI}
 */
export class CandidatesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public apiCandidateGet(options?: RawAxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).apiCandidateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public apiCandidateIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).apiCandidateIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public apiCandidateIdGet(id: number, options?: RawAxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).apiCandidateIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CandidateDtoRequest} candidateDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public apiCandidateIdPut(id: number, candidateDtoRequest: CandidateDtoRequest, options?: RawAxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).apiCandidateIdPut(id, candidateDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CandidateDtoRequest} candidateDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public apiCandidatePost(candidateDtoRequest: CandidateDtoRequest, options?: RawAxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).apiCandidatePost(candidateDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public apiVacancyIdCandidateGet(id: number, options?: RawAxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).apiVacancyIdCandidateGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CandidateDtoRequest} candidateDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public apiVacancyIdCandidatePost(id: number, candidateDtoRequest: CandidateDtoRequest, options?: RawAxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).apiVacancyIdCandidatePost(id, candidateDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getMe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginPost: async (authRequest: AuthRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRequest' is not null or undefined
            assertParamExists('apiUserLoginPost', 'authRequest', authRequest)
            const localVarPath = `/api/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshRequest} refreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRefreshPost: async (refreshRequest: RefreshRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshRequest' is not null or undefined
            assertParamExists('apiUserRefreshPost', 'refreshRequest', refreshRequest)
            const localVarPath = `/api/user/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserGetMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLoginPost(authRequest: AuthRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLoginPost(authRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RefreshRequest} refreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRefreshPost(refreshRequest: RefreshRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRefreshPost(refreshRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserRefreshPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetMeGet(options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiUserGetMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginPost(authRequest: AuthRequest, options?: any): AxiosPromise<AuthResponse> {
            return localVarFp.apiUserLoginPost(authRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshRequest} refreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRefreshPost(refreshRequest: RefreshRequest, options?: any): AxiosPromise<AuthResponse> {
            return localVarFp.apiUserRefreshPost(refreshRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetMeGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLoginPost(authRequest: AuthRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserLoginPost(authRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshRequest} refreshRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRefreshPost(refreshRequest: RefreshRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRefreshPost(refreshRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VacancyApi - axios parameter creator
 * @export
 */
export const VacancyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vacancy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacancyIdDelete', 'id', id)
            const localVarPath = `/api/vacancy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacancyIdGet', 'id', id)
            const localVarPath = `/api/vacancy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VacancyDtoRequest} vacancyDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdPut: async (id: number, vacancyDtoRequest: VacancyDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVacancyIdPut', 'id', id)
            // verify required parameter 'vacancyDtoRequest' is not null or undefined
            assertParamExists('apiVacancyIdPut', 'vacancyDtoRequest', vacancyDtoRequest)
            const localVarPath = `/api/vacancy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacancyDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VacancyDtoRequest} vacancyDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyPost: async (vacancyDtoRequest: VacancyDtoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacancyDtoRequest' is not null or undefined
            assertParamExists('apiVacancyPost', 'vacancyDtoRequest', vacancyDtoRequest)
            const localVarPath = `/api/vacancy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacancyDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancyApi - functional programming interface
 * @export
 */
export const VacancyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VacancyDtoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.apiVacancyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.apiVacancyIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.apiVacancyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {VacancyDtoRequest} vacancyDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyIdPut(id: number, vacancyDtoRequest: VacancyDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyIdPut(id, vacancyDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.apiVacancyIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VacancyDtoRequest} vacancyDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyPost(vacancyDtoRequest: VacancyDtoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyPost(vacancyDtoRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.apiVacancyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VacancyApi - factory interface
 * @export
 */
export const VacancyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancyApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyGet(options?: any): AxiosPromise<Array<VacancyDtoResponse>> {
            return localVarFp.apiVacancyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdDelete(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.apiVacancyIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdGet(id: number, options?: any): AxiosPromise<VacancyDtoResponse> {
            return localVarFp.apiVacancyIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VacancyDtoRequest} vacancyDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyIdPut(id: number, vacancyDtoRequest: VacancyDtoRequest, options?: any): AxiosPromise<VacancyDtoResponse> {
            return localVarFp.apiVacancyIdPut(id, vacancyDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VacancyDtoRequest} vacancyDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyPost(vacancyDtoRequest: VacancyDtoRequest, options?: any): AxiosPromise<VacancyDtoResponse> {
            return localVarFp.apiVacancyPost(vacancyDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancyApi - object-oriented interface
 * @export
 * @class VacancyApi
 * @extends {BaseAPI}
 */
export class VacancyApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public apiVacancyGet(options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).apiVacancyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public apiVacancyIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).apiVacancyIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public apiVacancyIdGet(id: number, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).apiVacancyIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VacancyDtoRequest} vacancyDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public apiVacancyIdPut(id: number, vacancyDtoRequest: VacancyDtoRequest, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).apiVacancyIdPut(id, vacancyDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VacancyDtoRequest} vacancyDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public apiVacancyPost(vacancyDtoRequest: VacancyDtoRequest, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).apiVacancyPost(vacancyDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



